import { Base64 } from "js-base64";
//数组对象去重
export const removeSame = (arr, prop) => {
    const map = new Map();
    return arr.filter((obj) => !map.has(obj[prop]) && map.set(obj[prop], true));
}

//Base64加密
export const setBase64 = (val) => {
    // let data = Base64.encode(JSON.stringify(val))
    return val;
}
//Base64解密
export const getBase64 = (val) => {
    // if (!val) return;
    // if(){
        
    // }
    // let data = JSON.parse(Base64.decode(val))
    return val;
}