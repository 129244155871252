<template>
  <div class="menu">
    <el-menu
      :default-active="defaultActive"
      class="el-menu"
      background-color="#fff"
      text-color="#333"
      active-text-color="#409EFF"
    >
      <SideItem :menu="menu"></SideItem>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SideItem from "./components/sideItem.vue";
export default {
  components: {
    SideItem,
  },
  props: {
    defaultActive: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.menu = this.$router.options.routes.filter((f, i) => i > 3);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.menu {
  width: 230px;
  overflow: hidden;
  .el-menu {
    width: 100%;
  }
  ::v-deep .el-submenu__title {
    text-align: left;
  }
  ::v-deep .el-menu-item {
    text-align: left;
  }
}
</style>
