import request from '@/utils/request'
export function getCaptchaImg() {
  return request({
    url: '/api/common/captcha/img',
    method: 'get',
    responseType: 'blob',
    requestBaseUrl: 'api'
  })
}
//获取可以登录的渠道商
export function getLoginChannel(query) {
  return request({
    url: '/api/login/getLoginChannel',
    method: 'post',
    params: query
  })
}
//发送验证码
export function sendCode(query) {
  return request({
    url: '/api/common/sms/sendCode',
    method: 'post',
    params: query,
    requestBaseUrl: 'api'
  })
}
//登录
export function submitLogin(query) {
  return request({
    url: '/api/login/login',
    method: 'post',
    params: query
  })
}
//企业列表
export function businessCompany(query) {
  return request({
    url: '/api/v2/lxkCompany/company',
    method: 'post',
    params: query,
    loading: true
  })
}
//费率列表
export function feeList(query) {
  return request({
    url: '/api/v2/taxServiceFee/feeList',
    method: 'post',
    params: query,
    loading: true
  })
}
//导出费率列表
export function feeListExport(query) {
  return request({
    url: '/api/v2/taxServiceFee/feeListExport',
    method: 'post',
    params: query,
    loading: true
  })
}
//代理商详细
export function businessCityDetail(query) {
  return request({
    url: '/api/v2/lxkCompany/cityDetail',
    method: 'post',
    params: query,
    loading: true
  })
}
//企业详细
export function businessCompanyDetail(query) {
  return request({
    url: '/api/v2/lxkCompany/companyDetail',
    method: 'post',
    params: query,
    loading: true
  })
}
//切换登录
export function changeLogin(query) {
  return request({
    url: '/api/login/changeLogin',
    method: 'post',
    params: query
  })
}
//企业详细
export function businessCompanyExport(query) {
  return request({
    url: '/api/v2/lxkCompany/companyExport',
    method: 'post',
    params: query,
    loading: true
  })
}
//发票统计列表
export function invoiceCompany(query) {
  return request({
    url: '/api/v2/invoiceStatistics/company',
    method: 'post',
    params: query,
    loading: true
  })
}
//发票统计导出
export function invoiceCompanyExport(query) {
  return request({
    url: '/api/v2/invoiceStatistics/companyExport',
    method: 'post',
    params: query,
    loading: true
  })
}
//开票查询列表
export function InvoicingInvoiceList(query) {
  return request({
    url: '/api/v2/invoiceStatistics/invoiceList',
    method: 'post',
    params: query,
    loading: true
  })
}
//开票查询列表导出
export function InvoicingInvoiceListExport(query) {
  return request({
    url: '/api/v2/invoiceStatistics/invoiceListExport',
    method: 'post',
    params: query,
    loading: true
  })
}
//推广商分详细
export function feeListDetail(query) {
  return request({
    url: '/api/v2/taxServiceFee/feeListDetail',
    method: 'post',
    params: query,
    loading: true
  })
}
//零工列表
export function lgList(query) {
  return request({
    url: '/api/v2/agentData/lgList',
    method: 'post',
    params: query,
    loading: true
  })
}
//任务管理列表
export function taskList(query) {
  return request({
    url: '/api/v2/taxServiceFee/taskList',
    method: 'post',
    params: query,
    loading: true
  })
}