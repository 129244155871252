import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex) // 使用Vuex
import user from './user.js';
import getters from './getters.js';
import createPersistedState from "vuex-persistedstate"
export default new Vuex.Store({
    modules: {
        user
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        paths: ['user']
    })],
    getters
})