import { submitLogin,changeLogin } from '@/api/common'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { routerDispose } from "@/utils/routerDispose";
const state = {
  userInfo: {
    token: '',
    avatar: '',
    menu: [],
    userName: ''
  },
  tag: []
}

const mutations = {
  SET_USER_INFO(state, val) {
    state.userInfo = val
  },
  ADD_ROUTE(state) {
    let routerList = JSON.parse(JSON.stringify(state.userInfo.menu))
    if (router.getRoutes().length == 3) {
      routerList = routerDispose(routerList)//路由动态添加
      routerList.forEach((e) => {
        router.addRoute(e)
      })
    }
  },
  ADD_TAG(state, tag) {
    let tags = state.tag
    let inx = tags.findIndex(f => f.path === tag.path);
    if (inx == -1) {
      state.tag.push(tag)
    }
  },
  DEL_TAG(state, tag) {
    let tags = state.tag
    let inx = tags.findIndex(f => f.path === tag.path);
    if (inx) {
      state.tag.splice(inx, 1)
      let obj = state.tag[inx - 1]
      router.replace(obj.path)
    }
  },
  DEL_TAG_ALL(state, tag){
    state.tag = [];
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { id, phone, code: phone_code } = userInfo
    return new Promise((resolve, reject) => {
      let params = {
        id,
        phone,
        phone_code
      }
      submitLogin(params).then(res => {
        if (res.err_no == 0) {
          commit('SET_USER_INFO', res.results);
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  changeLogin({ commit }, id){
    return new Promise((resolve, reject) => {
      changeLogin({id:id}).then(res => {
        if (res.err_no == 0) {
          commit('SET_USER_INFO', res.results);
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user logout
  logout({ commit, state, dispatch }) {
    commit('SET_USER_INFO', {});
    commit('DEL_TAG_ALL');
    router.replace({ path: '/login' });
    location.reload()
  },
  //添加路由
  addRoute({ commit }) {
    commit('ADD_ROUTE')
  },
  //添加标签
  addTag({ commit }, tag) {
    commit('ADD_TAG', tag)
  },
  //删除标签
  delTag({ commit }, tag) {
    commit('DEL_TAG', tag)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
