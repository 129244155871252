import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import "../permission";
import { fitColumns } from '@/utils/table'
import 'element-ui/lib/theme-chalk/index.css';
import "@/style/index.scss";
import Table from "@/components/table";
import { message } from '@/utils/resetMessage';
import '@/assets/iconFont/iconfont.css';
import dayjs from 'dayjs'
import "amfe-flexible"
function px2rem(px){
  if(/%/ig.test(px)){ // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  }else{
    return (parseFloat(px) / 192) + 'rem'
  }
}
Vue.prototype.$px2rem = px2rem // 放到全局
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$msg = message;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.component('Table', Table);
Vue.directive('fit-columns', fitColumns)
Vue.directive('debounce', {
  // 指令第一次绑定到元素时
  bind: function (el, binding, vnode) {
    let wait = binding.value; // 防抖时间
    if (!wait) { // 用户若不设置防抖时间，则默认1s
      wait = 1000;
    }
    let timer;
    el.addEventListener('click', event => {
      if (!timer) { // 第一次执行: 不阻止click⌚️
        timer = setTimeout(() => {
          timer = null;
        }, wait);
      } else {
        clearTimeout(timer)
        timer = setTimeout(() => {
          timer = null;
        }, wait);
        event && event.stopImmediatePropagation();
      }
    }, true);
  }
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
