<template>
  <div class="header">
    <div v-if="userInfo.logo" class="logo">
      <img :src="userInfo.logo" class="logoImg2" />
    </div>
    <div v-else class="logo">
      <img src="@/assets/lxkLogo.png" class="logoImg" />
      <div class="logo_title">灵薪卡</div>
    </div>
    <div class="header-right">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="(e, i) in matchedArr" :key="i">
            {{ e.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="userBox">
        <el-image src="https://cdn.usharejob.com/uploads/images/2021/07/06/65bfa91852c8498524c59289f4a783a2.png"
          class="userImg"></el-image>
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="el-dropdown-link">
            <div class="channelName">
              {{ userInfo.name }}（{{ userInfo.channelName }}）
            </div>
            <div></div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item" v-for="(item, index) in list" :key="index">{{ item.name
              }}</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginChannel } from "@/api/common";
export default {
  props: {
    matchedArr: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      list: [],
    };
  },

  computed: {
    userInfo() {
      let info = this.$store.getters.userInfo;
      console.log(info);
      let userInfo = {};
      if (info) {
        let { name, logo } = info.collection_channel || {};
        userInfo = {
          logo,
          channelName: name,
          name: info.name,
          phone: info.phone,
          collection_channel_id: info.collection_channel_id,
        };
      }
      return userInfo || "";
    },
  },

  created() {
    let { phone, collection_channel_id } = this.userInfo;
    let params = {
      phone: phone,
    };
    getLoginChannel(params).then((res) => {
      let list = res.results;
      this.list = list.filter((f) => f.id != collection_channel_id);
    });
  },

  methods: {
    async handleCommand(command) {
      if (command == "logout") {
        this.$store.dispatch("user/logout");
      } else {
        await this.$store.dispatch("user/changeLogin", command.id);
        location.reload();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 50px;
  background-color: #78b5f4;
  display: flex;
  align-items: center;

  .channelName {
    font-size: 16px;
  }

  .logo {
    width: 230px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3692f1;
    font-size: 28px;
    color: #fff;
    letter-spacing: 2px;

    .logo_title {
      margin-left: 5px;
      margin-bottom: 3px;
    }

    .logoImg2 {
      width: 100%;
      height: 100%;
    }

    .logoImg {
      width: 30px;
      height: 35px;
    }
  }

  .header-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .breadcrumb {
    padding-left: 10px;
    box-sizing: border-box;
    color: #fff;
  }

  ::v-deep .el-breadcrumb__inner {
    color: #fff !important;
    font-size: 17px !important;
    letter-spacing: 1px !important;
  }

  ::v-deep .el-icon-arrow-right {
    color: #fff !important;
  }

  .userImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .el-dropdown-link {
    cursor: pointer;
    text-align: left;
    color: #fff;
  }

  .userBox {
    display: flex;
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>