<template>
  <div class="layout">
    <div class="layout-header">
      <Header :matchedArr="matchedArr"></Header>
    </div>
    <div class="layout-content">
      <div class="layout-content-left">
        <Menu :defaultActive="defaultActive"></Menu>
      </div>
      <div class="layout-content-right">
        <Tag :activePath="defaultActive"></Tag>
        <Main></Main>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./header.vue";
import Menu from "./menu.vue";
import Main from "./main.vue";
import Tag from "./tag.vue";
import { removeSame } from "@/utils/tool";
export default {
  components: {
    Header,
    Menu,
    Main,
    Tag,
  },
  data() {
    return {
      defaultActive: "",
      activePath: "",
      matchedArr: [],
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        let { meta, path, matched, name } = val;
        this.$store.dispatch("user/addTag", {
          label: meta.title,
          path: path,
          name: name,
        });
        this.defaultActive = val.path;
        let arr = matched.map((m) => m.meta);
        this.matchedArr = removeSame(arr, "title");
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
  overflow: hidden;
}

.layout-header {}

.layout-content {
  display: flex;
  height: calc(100% - 50px);
}

.layout-content-left {
  background-color: #fff;
}

.layout-content-right {
  background-color: #F0F5F9;
}
</style>