function adjustColumnWidth(table) {
    const colgroup = table.querySelector('colgroup')
    const colDefs = [...colgroup.querySelectorAll('col')]
    colDefs.forEach(col => {
        const clsName = col.getAttribute('name')
        const cells = [...table.querySelectorAll(`td.${clsName}`), ...table.querySelectorAll(`th.${clsName}`)]
        if (cells[0] && cells[0].classList && cells[0].classList.length && cells[0].classList.contains('exclude')) {
            return
        }
        const widthList = cells.map(el => {
            if (el.querySelector('.cell') && el.querySelector('.cell').scrollWidth) {
                return el.querySelector('.cell').scrollWidth
            } else {
                return 0
            }
        })
        const max = Math.max(...widthList)
        table.querySelectorAll(`col[name=${clsName}]`).forEach(el => {
            el.style.width = max + 'px'
        })
    })
}
export const fitColumns = {
    update() {},
    bind() {},
    inserted(el) {
        setTimeout(() => {
            adjustColumnWidth(el)
        }, 300)
    },
    componentUpdated(el) {
        el.classList.add('r-table')
        setTimeout(() => {
            adjustColumnWidth(el)
        }, 300)
    },
    unbind() {}
}