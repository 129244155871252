import store from "@/store";
export function getToken() {
  let token = '';
  let vuex = JSON.parse(sessionStorage.getItem('vuex'));
  let { userInfo } = vuex?.user || {};
  if (userInfo) {
    token = userInfo ? userInfo.token : '';
  }
  return token;
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
