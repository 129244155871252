<template>
  <div>
    <div v-for="(item, i) in menu" :key="i">
      <el-menu-item v-if="!item.children" :index="item.path" @click="menuClick(item)">
        <i :class="['iconfont', 'icon', item.meta.icon]"></i>
        <span slot="title" :class="item.meta.icon ? 'menu_style' : 'menu_style2'">{{
          item.meta ? item.meta.title : ""
        }}</span>
      </el-menu-item>
      <div v-else>
        <div v-if="item.meta.layer">
          <sideItem :menu="item.children"></sideItem>
        </div>
        <div v-else>
          <el-submenu :key="i" :index="item.path">
            <template slot="title">
              <i :class="['iconfont', 'icon', item.meta.icon]"></i>
              <span slot="title" class="menu_style">{{ item.meta ? item.meta.title : "" }}</span>
            </template>
            <sideItem :menu="item.children"></sideItem>
          </el-submenu>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sideItem",
  props: {
    menu: { type: Array },
  },
  methods: {
    menuClick(e) {
      let { path, name } = e;
      this.$router.push({ path: path });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  font-size: 18px;
  color: #409EFF;
  margin-right: 10px;
}

.menu_style {
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 1px;
}

.menu_style2 {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
}

::v-deep .el-menu-item:hover {
  background: #f0f5f9 !important;
}

::v-deep .is-active {
  background: #f0f5f9 !important;
}

::v-deep .el-submenu__title:hover {
  background: #f0f5f9 !important;
}
</style>