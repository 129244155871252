import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Layout from "@/layout";
Vue.use(VueRouter);

let routes = [
  {
    path: "/login",
    component: () => import("@/page/login.vue"),
    name: "login",
  },
  {
    path: "/404",
    component: () => import("@/page/404.vue"),
    name: "notfound",
  },
  {
    path: "/",
    redirect: { path: "/login" },
  },
  {
    path: "*",
    redirect: { path: "/404" },
  },
  {
    id: 0,
    path: '/business',
    component: Layout,
    redirect: '/business',
    meta: {
      title: '商户管理',
      icon: 'icon-shanghuguanli',
      layer:true//单层
    },
    children: [
      {
        id: 9,
        path: '/business',
        component: () => import('@/views/business/index'),
        name: 'business',
        meta: {
          title: '商户管理',
          icon: 'icon-shanghuguanli'
        }
      }
    ]
  },
  {
    id: 0,
    path: '/invoice',
    component: Layout,
    redirect: '/invoice',
    meta: {
      title: '发票统计',
      icon: 'icon-dianfeifapiao',
      layer:true
    },
    children: [
      {
        id: 9,
        path: '/invoice',
        component: () => import('@/views/invoice/index'),
        name: 'invoice',
        meta: {
          title: '发票统计',
          icon: 'icon-dianfeifapiao'
        }
      }
    ]
  },
  {
    id: 0,
    path: '/promotion',
    component: Layout,
    redirect: '/promotion',
    meta: {
      title: '推广方分润',
      icon: 'icon-tuiguang',
      layer:false
    },
    children: [
      {
        id: 9,
        path: '/promotion',
        component: () => import('@/views/promotion/index'),
        name: 'promotion',
        meta: {
          title: '任务结算表',
          // icon: 'icon-tuiguang'
        }
      },
      {
        id: 9,
        path: '/promotion/promotionDetail',
        component: () => import('@/views/promotion/promotionDetail'),
        name: 'promotionDetail',
        meta: {
          title: '发佣结算记录表',
          // icon: 'icon-tuiguang'
        }
      }
    ]
  },
  {
    id: 0,
    path: '/Invoicing',
    component: Layout,
    redirect: '/Invoicing',
    meta: {
      title: '开票查询',
      icon: 'icon-qupiaochaxun',
      layer:true
    },
    children: [
      {
        id: 9,
        path: '/Invoicing',
        component: () => import('@/views/Invoicing/index'),
        name: 'Invoicing',
        meta: {
          title: '开票查询',
          icon: 'icon-qupiaochaxun'
        }
      }
    ]
  },
  {
    id: 0,
    path: '/sidelineMarket',
    component: Layout,
    redirect: '/sidelineMarket',
    meta: {
      title: '零工市场',
      icon: 'icon-renlishichang',
      layer:false
    },
    children: [
      {
        id: 9,
        path: '/sidelineMarket',
        component: () => import('@/views/sidelineMarket/index'),
        name: 'sidelineMarket',
        meta: {
          title: '零工',
          // icon: 'icon-tuiguang'
        }
      }
    ]
  },
  
  {
    id: 0,
    path: '/task',
    component: Layout,
    redirect: '/task',
    meta: {
      title: '任务',
      icon: 'icon-gongzuorenwu',
      layer:false
    },
    children: [
      {
        id: 9,
        path: '/task',
        component: () => import('@/views/task/index'),
        name: 'task',
        meta: {
          title: '任务管理',
          // icon: 'icon-tuiguang'
        }
      }
    ]
  }
];
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
// routes = routes.concat(store.getters.menu);
const createRouter = () => new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
});
const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router;
