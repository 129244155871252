import router from '@/router'
import store from '@/store'
import { routerDispose } from "@/utils/routerDispose";
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth'
NProgress.configure({ showSpinner: false })
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const hasToken = getToken()
  if (hasToken) {
    if (to.path !== '/login') {
      next();
    }else{
      next(false);
    }
    NProgress.done()
  } else {
    if (to.path === '/login') {
      next();
    } else {
      next('/login');
    }
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})