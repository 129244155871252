export function routerDispose(data) {
    return menu(data)
}
const menu = (arr) => {
    if (arr.length > 0) {
        arr.forEach(item => {
            if (item.component == 'Layout') {
                item.component = () => import('@/layout')
            } else {
                item.component = loadView(item.component)
            }
            if (item.children) {
                menu(item.children)
            }
        })
    }
    return arr
}
export const loadView = (view) => { // 路由懒加载
    return (resolve) => require([`@/views${view}`], resolve)
}